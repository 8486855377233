import _ from 'lodash';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
// ? TODO:  <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} />
import { SHOW_COMMEMORATE } from 'src/constants';
/* -------------------------------- KEY_TYPE -------------------------------- */
export const KEY_ADS_POSITION = {
  BILLBOARD: 'billboard',
  STICKY_1: 'sticky-1',
  STICKY_2: 'sticky-2',
  STICKY_3: 'sticky-3',
  INARTICLE_1: 'inarticle-1',
  INARTICLE_2: 'inarticle-2',
  INARTICLE_3: 'inarticle-3',
  POSTARTICLE: 'postarticle',
  AD1X1: 'ad1x1',
  LIGHTBOX: 'light-box',
  ELECTION_1: 'election-1',
  ELECTION_2: 'election-2',
  ELECTION_3: 'election-3',
  ELECTION_4: 'election-4',
  ELECTION_5: 'election-5',
  ELECTION_6: 'election-6',
  ELECTION_7: 'election-7'
};

export const Ads = ({ slotId = '', slotDefine = '', className = '', slotSize = [], position = '', adsBox = false, handler = () => {} }) => {
  useEffect(() => {
    window.googletag.cmd.push(() => {
      window.googletag.defineSlot(slotDefine, slotSize, slotId).addService(window.googletag.pubads()).setCollapseEmptyDiv(true, true);
      window.googletag.pubads().enableSingleRequest();
      window.googletag.enableServices();

      window.googletag.cmd.push(function () {
        window.googletag.display(slotId);
      });
    });
  }, []);
  // console.log('slotId', slotId);
  if (_.isEmpty(slotId) || _.isEmpty(slotDefine) || _.isEmpty(slotSize)) return null;
  // console.log(slotSize);
  switch (position) {
    case 'light-box':
      return (
        <div>
          <AdsLightBox id='ads-nlightbox' className={`light-box-ads ${!adsBox ? 'close' : 'open'}`}>
            <div className={'light-box'} onClick={handler}>
              <AdsWrapper className={className}>
                <div id={slotId} />
              </AdsWrapper>
            </div>
          </AdsLightBox>
        </div>
      );
    default:
      return (
        <AdsWrapper className={className}>
          {/* {className} */}
          <div id={slotId} style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }} />
        </AdsWrapper>
      );
  }
};

const AdsLightBox = styled.div`
  z-index: 2147483647;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4142429c;

  &.close {
    display: none;
  }
  .light-box {
    position: relative;
    &::before {
      content: 'X';
      cursor: pointer;
      position: absolute;
      top: -24px;
      right: 0;
      color: rgba(0, 0, 0, 0.788);
      background-color: #fafafab7;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      padding: 2px 7px;
    }
  }
`;

const AdsWrapper = styled.div`
  &.ads-billboard {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
    min-height: 250px;
    iframe {
      margin: auto;
    }
    /* @media (max-width: 767px) {
      min-height: 300px;
    } */
  }
  &.ads-election-1,
  &.ads-election-2,
  &.ads-election-3,
  &.ads-election-4 {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.ads-ad1x1 {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }
  &.ads-sticky-1,
  &.ads-sticky-2,
  &.ads-sticky-3 {
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 30px;
  }
  &.ads-postarticle {
    position: sticky;
    bottom: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
  }

  &.ads-inarticle-1,
  &.ads-inarticle-2,
  &.ads-inarticle-3 {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 30px auto;
    img,
    iframe {
      max-width: 100%;
      display: block;
      margin: auto;
    }
  }
`;

const DynamicAds = ({ position = '', data = [], adsBox = false, handler = () => {} }) => {
  // const _adsArray = !_.isEmpty(data) ? data.filter(_data => _data?.section === ModifyAdsSection(page, _data)) : [];
  // console.log('_adsArray', page, position, _adsArray[0]);
  const isMb = useMediaQuery({ query: '(max-width: 1023px)' });
  const isDt = useMediaQuery({ query: '(min-width: 1024px)' });
  const _adsArray = !_.isEmpty(data) ? data : [];
  // เช็คตำแหน่ง ADS
  const _adsSlot = _adsArray.find(_data => _data?.position === position);
  // const isDesktop = useDesktop();
  // const slotSize = isMb ? _adsSlot?.sizeMobile : _adsSlot?.sizeDesktop;
  // if (!isDesktop && _.isEmpty(_adsSlot?.sizeMobile)) {
  //   return <div className='ads'></div>;
  // }
  // if (isDesktop && _.isEmpty(_adsSlot?.sizeDesktop)) {
  //   return <div className='ads'></div>;
  // }

  if (!_.isEmpty(_adsSlot)) {
    // console.log('DynamicAds', _adsSlot, _adsSlot?.sizeDesktop);
    // console.log(_adsSlot);
    // const isDesktop = useDesktop();
    // if (!isDesktop && _.isEmpty(_adsSlot?.sizeMobile)) {
    //   return <div className='ads'></div>;
    // }
    // if (isDesktop && _.isEmpty(_adsSlot?.sizeDesktop)) {
    //   return <div className='ads'></div>;
    // }
    return (
      <SlotWrapper>
        <div className='mobile'>
          {!isDt && (
            <Ads
              adsBox={adsBox}
              handler={handler}
              className={`ads-${position}`}
              slotDefine={_adsSlot?.slotDefine}
              slotId={_adsSlot?.slotId}
              slotSize={_adsSlot?.sizeMobile}
              position={position}
            />
          )}
        </div>
        <div className='desktop'>
          {!isMb && (
            <Ads
              adsBox={adsBox}
              handler={handler}
              className={`ads-${position}`}
              slotDefine={_adsSlot?.slotDefine}
              slotId={_adsSlot?.slotId}
              slotSize={_adsSlot?.sizeDesktop}
              position={position}
            />
          )}
        </div>
      </SlotWrapper>
    );
  }
  return null;
};

export default DynamicAds;

const SlotWrapper = styled.div`
  .mobile {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }
  .desktop {
    display: block;
    @media (max-width: 1025px) {
      display: none;
    }
  }
`;

// :: Default ::
// <Ads
//    className='ads'
//    slotDefine='/231599934,12270527/springnews/homepage/billboard'
//    slotId='div-gpt-ad-1622722566999-0'
//    slotSize=[[300, 250], [336, 280]] />
