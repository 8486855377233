import _ from 'lodash';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useEffect, useRef, useState } from 'react';
import { IS_NO_ROBOT_INDEX } from 'src/constants';
import { IMAGE_DEFAULT, WEB_DESC, WEB_KEYWORD, WEB_NAME, WEB_TITLE, WEB_URL } from 'src/constants/layout';
import { KEY_ADS_POSITION } from 'src/utils/ads/dynamic-ads';
import { isShouldFetch } from 'src/utils/detect-scrolling';
import { convertObjPath, delay, dropSomeField, escapeChar, timestamp } from 'src/utils/helper';
import { GET } from 'src/utils/services';

import WidgetEleCoverWrapper from 'src/components/widgets/widget-ele-cover';
import { decryptionClient } from 'src/services/member-auth/utils/decryption-payload';
import { convertDatasets } from 'src/utils/datasets';
import useUser from 'src/utils/useUser';

const DynamicAds = dynamic(import('src/utils/ads/dynamic-ads'));
const Layout = dynamic(import('src/components/layouts'));
const SectionOne = dynamic(import('src/components/sections/sections-home/section-one'));
const SectionMember = dynamic(import('src/services/member-auth/components/section-member'));
const SectionCover = dynamic(import('src/components/sections/section-event/section-cover'));
const SectionLandingPage = dynamic(import('src/components/sections/section-event/section-landing-page'));
const SectionTwoAndThree = dynamic(import('src/components/sections/sections-home/section-two-and-sevtion-three'), { ssr: false });
const SectionFour = dynamic(import('src/components/sections/sections-home/section-four'), { ssr: false });

const sectionsNeedShow = ['section-two', 'section-three'];

const PageIndex = ({ articles, ads, covers, dataLandingPage, dataPopular, ele66, showSectionElection }) => {
  const data = useUser();
  const [sessionSevId, setSessionSevId] = useState(null);
  const [sectionMember, setSectionMember] = useState({});
  useEffect(async () => {
    const checkUser = async () => {
      if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
        const response = await fetch('/api/auth/metadata', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.ok) {
          const res = await response.json();
          localStorage.setItem('login-meta-data', res);
          setSectionMember(decryptionClient(res));
        }
      }
    };
    const loginMetaData = localStorage.getItem('login-meta-data');
    const decryptLoginMetaData = !_.isEmpty(loginMetaData) ? decryptionClient(loginMetaData) : {};
    const expireCondition = !_.isEmpty(loginMetaData) ? new Date(decryptLoginMetaData?.expire) < new Date() : true;

    if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
      setSessionSevId(decryptionClient(data?.user));
      _.isEmpty(loginMetaData) || expireCondition ? checkUser() : setSectionMember(decryptLoginMetaData);
    }
  }, [data]);
  const sectionRef = useRef(0);
  const footerRef = useRef(false);
  const nextSectionIndexRef = useRef(0);
  const sectionsVisibleRef = useRef([]);
  const [sectionsVisible, setSectionsVisible] = useState([]);
  const [closeAd, setCloseBoxAd] = useState(true);
  const [adsBox, setAdsBox] = useState(true);
  const handleLightBoxClick = () => {
    setAdsBox(false);
  };

  const _article = {
    title: articles?.metaTag?.title || WEB_TITLE,
    keywords: articles?.metaTag?.keywords || WEB_KEYWORD,
    description: articles?.metaTag?.description || WEB_DESC
  };

  const articleDescriptionSeo = escapeChar(_article?.description) || WEB_DESC;

  const handleCloseBoxAd = () => {
    setCloseBoxAd(false);
  };

  /* ---------------------------- LOADMORE_ARTICLES --------------------------- */
  const handleScroll = async () => {
    if (isShouldFetch()) {
      document.removeEventListener('scroll', handleScroll);
      await loadMoreArticles();
    }
  };

  const loadMoreArticles = async () => {
    try {
      if (nextSectionIndexRef?.current < sectionsNeedShow.length) {
        if (isShouldFetch()) {
          const currentIndex = nextSectionIndexRef.current;
          const section = sectionsNeedShow[currentIndex];

          nextSectionIndexRef.current = currentIndex + 1;
          sectionsVisibleRef.current = [...sectionsVisibleRef.current, section];
          setSectionsVisible(sectionsVisibleRef.current);

          setTimeout(() => {
            loadMoreArticles();
          }, 1000);
        } else {
          document.addEventListener('scroll', handleScroll);
        }
      }
    } catch (err) {
      console.error(`${timestamp()} ==========> INDEX ERROR : ${nextSectionIndexRef.current}`, err);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);
  // /* ----------------------- DATALAYER : SCROLL_TRACKING ---------------------- */
  const handleScrollToCheckSectionOne = async () => {
    const sectionEl = document.getElementById('section-1');
    if (sectionEl) {
      sectionRef.current = 'section-1';
      document.removeEventListener('scroll', handleScrollToCheckSectionOne);
      //! DATALAYER
    }
  };

  const handleScrollToCheckSectionTwo = async () => {
    const sectionEl = await document.getElementById('section-2');
    if (sectionEl !== null) {
      if (sectionEl.offsetTop < document.documentElement.scrollTop) {
        sectionRef.current = 'section-2';
        document.removeEventListener('scroll', handleScrollToCheckSectionTwo);
        //! DATALAYER
      }
    }
  };

  const handleScrollToCheckSectionThree = async () => {
    const sectionEl = await document.getElementById('section-3');
    if (sectionEl !== null) {
      if (sectionEl.offsetTop < document.documentElement.scrollTop) {
        sectionRef.current = 'section-3';
        document.removeEventListener('scroll', handleScrollToCheckSectionThree);
        //! DATALAYER
      }
    }
  };

  const handleScrollToCheckSectionFour = async () => {
    const sectionEl = await document.getElementById('section-4');
    if (sectionEl !== null) {
      if (sectionEl.offsetTop < document.documentElement.scrollTop) {
        sectionRef.current = 'section-4';
        document.removeEventListener('scroll', handleScrollToCheckSectionFour);
        //! DATALAYER
      }
    }
  };

  const handleScrollToCheckSectionFive = async () => {
    const sectionEl = await document.getElementById('section-5');
    if (sectionEl !== null) {
      if (sectionEl.offsetTop < document.documentElement.scrollTop) {
        sectionRef.current = 'section-5';
        document.removeEventListener('scroll', handleScrollToCheckSectionFive);
        //! DATALAYER
        await delay(500);
        footerRef.current = true;
      }
    }
  };

  const handleScrollToCheckSectionFooter = async () => {
    if (footerRef.current) {
      document.removeEventListener('scroll', handleScrollToCheckSectionFooter);
      //! DATALAYER
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScrollToCheckSectionOne);
    return () => document.removeEventListener('scroll', handleScrollToCheckSectionOne);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollToCheckSectionTwo);
    return () => document.removeEventListener('scroll', handleScrollToCheckSectionTwo);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollToCheckSectionThree);
    return () => document.removeEventListener('scroll', handleScrollToCheckSectionThree);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollToCheckSectionFour);
    return () => document.removeEventListener('scroll', handleScrollToCheckSectionFour);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollToCheckSectionFive);
    return () => document.removeEventListener('scroll', handleScrollToCheckSectionFive);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollToCheckSectionFooter);
    return () => document.removeEventListener('scroll', handleScrollToCheckSectionFooter);
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <>
      {/* -------------------------------- NEXT_SEO -------------------------------- */}
      <NextSeo
        noindex={IS_NO_ROBOT_INDEX}
        nofollow={IS_NO_ROBOT_INDEX}
        title={_article.title}
        description={_article.description}
        openGraph={{
          type: 'article',
          locale: 'th_TH',
          url: WEB_URL.WEB,
          title: _article.title || WEB_TITLE,
          description: _article.description || WEB_DESC,
          images: [
            {
              url: IMAGE_DEFAULT,
              width: '1920',
              height: '1080',
              alt: WEB_NAME
            }
          ]
        }}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: _article.keywords || WEB_KEYWORD
          }
        ]}
      />
      {/* --------------------------------- HEADER --------------------------------- */}
      <Head>
        <link rel='canonical' href={WEB_URL.WEB} />
        <meta property='twitter:title' content={_article?.title || WEB_TITLE} />
        <meta property='twitter:description' content={_article?.description || WEB_DESC} />
        <meta property='twitter:image' content={IMAGE_DEFAULT} />

        {/* --------------------------------- Truehit -------------------------------- */}
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
                __th_page="home";
              `
          }}
        />
        {/* ---------------------------- SNIPPET : WebSite --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `
                  {
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "name": "${WEB_NAME}",
                    "url": "${WEB_URL.WEB}",
                    "description": "${articleDescriptionSeo}",
                    "sameAs": [
                      "${WEB_URL.FACEBOOK}",
                      "${WEB_URL.YOUTUBE}",
                      "${WEB_URL.INSTAGRAM}",
                      "${WEB_URL.TWITTER}",
                      "${WEB_URL.LINE}"
                    ],
                    "potentialAction": {
                      "@type": "SearchAction",
                      "target": "${WEB_URL.WEB}search?q={search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                }
              `
          }}
        />

        {/* ---------------------------- SPIPPET : WebSite --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `
                  {
                    "@context": "http://schema.org",
                    "@type": "WebPage",
                    "name": "${WEB_NAME}",
                    "description": "${articleDescriptionSeo}",
                    "publisher": {
                      "@type": "ProfilePage",
                      "name": "${WEB_NAME}"
                    }
                  }
                `
          }}
        />
      </Head>
      {/* ---------------------------- SECTION : LANDING PAGE ------------ */}
      <SectionLandingPage data={dataLandingPage} />
      {/* ---------------------------- SECTION : COVER ------------------- */}
      {covers.length > 0 && <SectionCover data={covers} />}
      <Layout sessionSevId={sessionSevId}>
        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.LIGHTBOX} data={ads} handler={() => handleLightBoxClick()} adsBox={adsBox} />}
        <div>
          {/* ---------------------- ADS : PPN - BILLBOARD [DESKTOP] -------------------- */}
          <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} data={ads} />
        </div>
        {/* <div>{isTabletOrMobile && <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} data={ads} />}</div> */}

        <WidgetEleCoverWrapper ele66={ele66} />
        {/* ---------------------------- SECTION : 1 ----------------------- */}
        <SectionOne ads={ads} data={articles} showSectionElection={showSectionElection} />
        {/* ---------------------------- SECTION : Member ----------------------- */}
        {!_.isEmpty(sessionSevId) && !_.isEmpty(sectionMember) && (
          <SectionMember
            data={{
              user: dropSomeField(sectionMember, ['menu', 'tags', 'articlesTags', 'articlesCategories'])?.user,
              menu: sectionMember?.menu,
              tags: sectionMember?.tags,
              articlesTags: sectionMember?.articlesTags,
              articlesCategories: sectionMember?.articlesCategories
            }}
          />
        )}

        {/* ---------------------------- SECTION : 2 ----------------------- */}
        {sectionsVisible.includes('section-two') && <SectionTwoAndThree ads={ads} dataPopular={dataPopular} />}
        {/* ---------------------------- SECTION : 3 ----------------------- */}
        {sectionsVisible.includes('section-three') && <SectionFour />}
        {/* ---------------------------- SECTION : THAILEAGUE -------------- */}
        {!_.isEmpty(ads.find(position => position.position === 'postarticle')) && (
          <div className={`close-box-ads ${!closeAd ? 'close' : 'open'}`}>
            <div onClick={handleCloseBoxAd} className='close-box'>
              <DynamicAds position={KEY_ADS_POSITION.POSTARTICLE} data={ads} />
            </div>
          </div>
        )}
        <div>
          <DynamicAds position={KEY_ADS_POSITION.AD1X1} data={ads} />
        </div>
      </Layout>
    </>
  );
};

export async function getServerSideProps(ctx) {
  const initProps = {
    statusCode: 200,
    errMessage: null,
    articles: {},
    ads: [],
    tags: [],
    covers: [],
    dataLandingPage: [],
    dataPopular: [],
    dataSectionVideo: null,
    adsHeader: null,
    ele66: [],
    showSectionElection: []
  };

  const payload = {
    block1: 5,
    block2: 4,
    block3: 4,
    headerLatestNews: 5
  };

  try {
    const scriptBrowser = await GET('/api/headerAds');
    if (!_.isEmpty(scriptBrowser)) {
      initProps.adsHeader = scriptBrowser;
      // console.log(scriptBrowser?.header_desktop);
    }
    const uri = `/api/v1.0/home/section-one${convertObjPath(payload)}`;
    const sectionData = await GET(uri);
    const coverData = await GET('/api/v1.0/imagecover');
    const landinPage = await GET('/api/landing-page');
    const popularData = await GET('/api/v1.0/categories/popular-news');
    const data = await GET('/api/datasets');
    initProps.ele66 = convertDatasets(data, 'section-ele66', false);
    if (!_.isEmpty(data)) {
      initProps.showSectionElection = (!_.isEmpty(data) && _.find(data, { topic: 'election-section-home-page' })?.dataSet) || [];
    }

    if (!_.isEmpty(sectionData)) {
      initProps.articles = sectionData;
      initProps.tags = sectionData.tags || [];
      initProps.ads = sectionData.ads || [];
    }
    if (!_.isEmpty(coverData)) initProps.covers = coverData;
    if (!_.isEmpty(landinPage)) initProps.dataLandingPage = landinPage;
    if (!_.isEmpty(popularData)) initProps.dataPopular = popularData;
    // if (!_.isEmpty(videoData)) initProps.dataVideo = videoData;
  } catch (err) {
    console.error(`${timestamp()} ==========> PAGE_INDEX ERROR : `, err.message);
    initProps.errMessage = err.message;
    initProps.statusCode = err?.response?.status || 500;
  }

  return {
    props: initProps
  };
}

export default PageIndex;
