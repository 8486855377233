import _ from 'lodash';
import { convertDatasets } from 'src/utils/datasets';
import styled from 'styled-components';

const WidgetEleCoverWrapper = ({ ele66 }) => {
  const convertData = {
    item1: convertDatasets(ele66?.[0], 'img-index-mb1', true),
    item2: convertDatasets(ele66?.[0], 'img-index-dkt1', true),
    item3: convertDatasets(ele66?.[0], 'lock-cate', true)
  };
  return (
    <>
      {!_.isEmpty(convertData.item1) && !_.isEmpty(convertData.item2) && (
        <SectionWrapper>
          <div>
            <a href={convertData?.item3 || '#'} title='เลือกตั้ง66' aria-label='เลือกตั้ง66'>
              {!_.isEmpty(convertData.item1) && (
                <img
                  src={`${convertData.item1}`}
                  className='spn-ele66-img-index-mb'
                  alt='เลือกตั้ง66'
                  title='เลือกตั้ง66'
                  width={'100%'}
                  height={'100%'}
                />
              )}
              {!_.isEmpty(convertData.item2) && (
                <img
                  src={`${convertData.item2}`}
                  className='spn-ele66-img-index-dkt'
                  alt='เลือกตั้ง66'
                  title='เลือกตั้ง66'
                  width={'100%'}
                  height={'100%'}
                />
              )}
            </a>
          </div>
        </SectionWrapper>
      )}
    </>
  );
};

export default WidgetEleCoverWrapper;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 20px auto -50px auto;
  @media (max-width: 690px) {
    margin: 0 auto -40px auto;
  }
  .spn-ele66-img-index-mb {
    display: none;
    @media (max-width: 690px) {
      display: block;
    }
  }
  .spn-ele66-img-index-dkt {
    display: block;
    @media (max-width: 690px) {
      display: none;
    }
  }
`;
